import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { textTransform } from '../../utils/helpers';
import { config } from '../../config';

import './scss/Priority.scss';
import { fetchCardById } from '../../redux/Slices/cardSlice';
import { togglePopover } from '../../redux/Slices/popoverSlice';


const Priority = ({ cardId }) => {
    const [priority, setPriority] = useState('Normal');

    const dispatch = useDispatch();

    const handlePriorityChange = async (newPriority) => {
        const token = localStorage.getItem('accessToken');

        await fetch(`${config.API_URI}/api/cards/${cardId}/priority`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ priority: newPriority })
        })
            .then(response => response.json())
            .then(data => {
                if (data.card) {
                    setPriority(data.card.priority);
                    dispatch(fetchCardById(cardId))
                    dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));
                }
            })
            .catch(error => console.error('Error updating priority:', error));
    };

    return (
        <div className="priority-action">
            <h2>Priority</h2>
            <ul className="group">
                {['Urgent', 'High', 'Normal', 'Low'].map((level) => (
                    <li
                        key={level}
                        onClick={() => handlePriorityChange(level)}
                        className={priority === level ? 'active' : ''}
                    >
                        <svg className={`zoobbe-flag-${textTransform(level, 'lowercase')}`} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#e8eaed">
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M14.4 6l-.24-1.2c-.09-.46-.5-.8-.98-.8H6c-.55 0-1 .45-1 1v15c0 .55.45 1 1 1s1-.45 1-1v-6h5.6l.24 1.2c.09.47.5.8.98.8H19c.55 0 1-.45 1-1V7c0-.55-.45-1-1-1h-4.6z" />
                        </svg>
                        {level}
                    </li>
                ))}
            </ul>
        </div>


    );
};

export default Priority;
