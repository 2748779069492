import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchArchivedData, removeArchivedList, resetArchivedCards } from '../../../redux/Slices/cardSlice';
import ArchivedCard from '../../Workspaces/ArchivedCard';
import Spinner from '../../Global/Spinner';
import './scss/Archived.scss';
import useHandlePopoverClick from '../../../hooks/useHandlePopoverClick';
import { showToast } from '../../../redux/Slices/toastSlice';
import { config } from '../../../config';

const Archived = ({ boardId, setBoard, className = '', isInNav = false }) => {
    const [activeTab, setActiveTab] = useState('cards');
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [isFocused, setIsFocused] = useState(false);
    const [isArchiving, setIsArchiving] = useState(false);

    const [debouncedQuery, setDebouncedQuery] = useState('');

    const { archivedCards, archivedLists, cardsCount, listsCount, hasMore, status } = useSelector((state) => state.card);
    const dispatch = useDispatch();
    const observer = useRef();

    const { handlePopoverClick } = useHandlePopoverClick();

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedQuery(searchTerm);
            setPage(1);
            dispatch(resetArchivedCards()); // Reset archivedCards on search
        }, 500);
        return () => clearTimeout(timer);
    }, [searchTerm]);

    useEffect(() => {
        dispatch(fetchArchivedData({ boardId, activeTab, page, debouncedQuery }));
    }, [page, debouncedQuery, activeTab]); // Ensure `activeTab` is included so it fetches the correct data.


    useEffect(() => {
        // dispatch(resetArchivedCards()); // Reset when tab changes
        if (archivedCards.length < 1 && activeTab === 'cards') {
            dispatch(fetchArchivedData({ boardId, activeTab, page, debouncedQuery }));
        }
        if (archivedLists.length < 1 && activeTab === 'lists') {
            dispatch(fetchArchivedData({ boardId, activeTab, page, debouncedQuery }));
        }
    }, [activeTab]);

    const lastCardRef = useCallback((node) => {
        if (status === 'loading') return;
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore) {
                setPage((prevPage) => prevPage + 1);
            }
        });

        if (node) observer.current.observe(node);
    }, [hasMore, status]);

    // const handleDeleteList = (listId) => setBoard((prev) => ({
    //     ...prev,
    //     actionLists: prev.actionLists.filter((list) => list._id !== listId),
    // }));

    console.log({ listsCount });

    // Archive the action list
    const handleListSendToBoard = async (listId, archivedStatus) => {
        setIsArchiving(true);
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/actionLists/archive/${listId}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ boardId, archivedStatus })
            });

            if (!response.ok) throw new Error('Failed to update the list');

            const data = await response.json();
            const { actionList } = data; // Extract the updated action list

            dispatch(removeArchivedList(listId)); // Remove the list from archive state

            setBoard((prevBoard) => {

                return {
                    ...prevBoard,
                    actionLists: [...prevBoard.actionLists, actionList]
                };
            });

            dispatch(showToast({
                message: archivedStatus ? 'List archived successfully!' : 'List restored successfully!',
                type: 'success'
            }));
        } catch (error) {
            dispatch(showToast({ message: 'Error updating the list: ' + error.message, type: 'error' }));
        } finally {
            setIsArchiving(false);
        }
    };


    return (

        <div className={`archived-container ${className}`}>

            {
                isInNav && (
                    <div className="menu-header">
                        <h3 className="menu-title">Archived items</h3>
                    </div>
                )
            }
            <div className="tabs">
                <button className={activeTab === 'cards' ? 'active' : ''} onClick={() => setActiveTab('cards')}>
                    Cards {cardsCount ? `(${cardsCount})` : ''}
                </button>
                <button className={activeTab === 'lists' ? 'active' : ''} onClick={() => setActiveTab('lists')}>
                    Lists {listsCount ? `(${listsCount})` : ''}
                </button>
            </div>
            <div className={`search-box${isFocused ? ' focused' : ''}`}>
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                />
            </div>
            <div className="content">
                {
                    <>
                        {activeTab === 'cards' ? (
                            archivedCards?.length > 0 ? (
                                <ol className="arhived-cards">
                                    {archivedCards.map((card, index) => {
                                        const isLastCard = index === archivedCards.length - 1;
                                        return (
                                            <li ref={isLastCard ? lastCardRef : null} key={card._id}>
                                                <ArchivedCard card={card} setBoard={setBoard} />
                                            </li>
                                        );
                                    })}
                                </ol>
                            ) : (
                                <>
                                    {
                                        status !== 'loading' && (
                                            <p className="empty-message" style={{ textAlign: 'center' }}>Nothing found</p>
                                        )
                                    }
                                </>

                            )
                        ) : (
                            archivedLists?.length > 0 ? (
                                <div className="lists">
                                    {archivedLists.map((list, index) => {
                                        const isLastList = index === archivedLists.length - 1;
                                        return (
                                            <div key={list._id} className="list-item" ref={isLastList ? lastCardRef : null}>
                                                <h3 className="list-title">{list.title}</h3>
                                                <div className="send-to-board" disabled={isArchiving} onClick={() => handleListSendToBoard(list._id, false)} data-tooltip-content="Send to board" data-tooltip-position="top">
                                                    {isArchiving ? (
                                                        <Spinner size={15} color="#ffffff" speed={1.5} strokeWidth={5} className="send-to-board-spinner" />
                                                    ) : (
                                                        <span className="material-symbols-outlined">refresh</span>
                                                    )}
                                                </div>
                                                <div
                                                    className="delete-archive-list"
                                                    id={'popover-delete-archived-list-' + list._id}
                                                    onClick={(e) => handlePopoverClick(e, 'deleteCard', { listId: list._id })}
                                                    data-tooltip-content="Delete"
                                                    data-tooltip-position="top"
                                                    data-popover-trigger
                                                >
                                                    <span className="material-symbols-outlined">delete</span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <>
                                    {
                                        status !== 'loading' && (
                                            <p className="empty-message" style={{ textAlign: 'center' }}>Nothing found</p>
                                        )
                                    }
                                </>
                            )
                        )}
                    </>
                }

                {status === 'loading' && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', height: '80px', marginTop: '25px' }}>
                        <Spinner size={30} color="#3498db" speed={1.4} />
                    </div>
                )}
            </div>

        </div>
    );
};

export default Archived;
