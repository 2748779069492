import React from 'react';
import PropTypes from 'prop-types';
import './ImagePlaceholder.scss';

const googleProfileColors = [
    "#F44336", "#E91E63", "#9C27B0", "#673AB7", "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4",
    "#009688", "#4CAF50", "#8BC34A", "#CDDC39", "#FFEB3B", "#FFC107", "#FF9800", "#FF5722",
    "#795548", "#9E9E9E", "#607D8B"
];

const getGoogleProfileColor = (char) => {
    if (!char || char.length === 0) return '#454f5999'; // Default color
    const index = (char.toUpperCase().charCodeAt(0) - 65) % googleProfileColors.length;
    return googleProfileColors[index];
};

const getContrastYIQ = (hexColor) => {
    hexColor = hexColor.replace('#', '');
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
};

const ImagePlaceholder = (props) => {
    const {
        text,
        size = 30,
        radius = "50%",
        fontSize = "15px",
        className = ""
    } = props;

    const bgColor = text ? getGoogleProfileColor(text.charAt(0)) : '#454f5999';
    const computedTextColor = getContrastYIQ(bgColor);

    const style = {
        backgroundColor: bgColor,
        width: size,
        height: size,
        color: computedTextColor,
        borderRadius: radius,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: fontSize,
        textTransform: 'uppercase',
        fontWeight: '400',
        // fontSize: '32px',
    };

    return (
        <div className={`image-placeholder ${className}`} style={style}>
            {text ? text.charAt(0) : ''}
        </div>
    );
};

ImagePlaceholder.propTypes = {
    text: PropTypes.string,
    size: PropTypes.number,
    radius: PropTypes.string,
    fontSize: PropTypes.string,
    className: PropTypes.string,
};

export default ImagePlaceholder;
