import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../redux/Slices/toastSlice';
import { config } from '../../config';
import './scss/index.scss';
import ProfileNavbar from './ProfileNavbar';
import Spinner from '../Global/Spinner';
import { fetchUserSuccess } from '../../redux/Slices/userSlice';

const UserProfile = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [profilePictureFile, setProfilePictureFile] = useState(null);
    const [profilePicture, setProfilePicture] = useState(null);
    const [bio, setBio] = useState('');
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);

    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            setUserInfo(user);
            setUsername(user.user.username || '');
            setBio(user.user.bio || '');
            setProfilePicture(user.user.profilePicture || '');
        }
    }, [user]);

    if (!user) {
        return null;
    }

    const handleProfilePictureChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfilePictureFile(file);
            const fileURL = URL.createObjectURL(file);
            setProfilePicture(fileURL);
        }
    };

    const handleBioChange = (event) => {
        setBio(event.target.value);
    };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handleSave = async () => {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            return false;
        }

        const updatedData = {
            bio,
            username
        };

        setLoading(true);

        try {
            let fileUrl = '';
            if (profilePictureFile) {
                const formData = new FormData();
                formData.append('profilePicture', profilePictureFile);

                const uploadResponse = await fetch(config.API_URI + '/api/users/upload-profile-picture', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    body: formData
                });

                if (!uploadResponse.ok) {
                    dispatch(showToast({ message: 'Failed to upload profile picture', type: 'error' }));
                    return;
                }

                const uploadData = await uploadResponse.json();
                fileUrl = uploadData.fileUrl;
            }

            const response = await fetch(config.API_URI + '/api/users/update', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...updatedData,
                    profilePicture: fileUrl
                })
            });

            if (!response.ok) {
                dispatch(showToast({ message: 'Failed to update profile', type: 'error' }));
                return;
            }

            const data = await response.json();
            setUserInfo(data.user);
            dispatch(fetchUserSuccess(data));
            dispatch(showToast({ message: 'Profile updated successfully.', type: 'success' }));
        } catch (error) {
            dispatch(showToast({ message: 'Error updating profile: ' + error.message, type: 'error' }));
        } finally {
            setLoading(false);
        }
    };

    const fileInputRef = React.createRef();

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div className="profile-page">
            <ProfileNavbar />
            <div className="bottom-profile-content">
                <div className="content">
                    <h1>Manage your personal information</h1>
                    <p>This is an <strong><Link to={'#'}>Zoobbe</Link></strong> account. Edit your personal information and visibility settings through your Zoobbe profile.</p>
                    <div className="about-section">
                        <h2>About</h2>
                        <div className="form-group">
                            <label>Username</label>
                            <input type="text" value={username} onChange={handleUsernameChange} />
                        </div>

                        <div className="form-group">
                            <label>Bio</label>
                            <textarea value={bio} onChange={handleBioChange} placeholder="Write something about yourself..."></textarea>
                        </div>

                        <div className="form-group">
                            <label>Profile Picture</label>
                            <div onClick={handleButtonClick} className="choose-file-button">
                                <span className="material-symbols-outlined">
                                    add_a_photo
                                </span>
                                Choose Picture
                            </div>
                            <input
                                type="file"
                                accept="image/jpeg, image/png"
                                name="profilePicture"
                                onChange={handleProfilePictureChange}
                                ref={fileInputRef}
                                style={{ display: 'none' }} // Hide the input
                            />

                            {profilePictureFile ? (
                                <div className="profile-picture">
                                    <img src={URL.createObjectURL(profilePictureFile)} alt={username} loading="lazy" />
                                </div>
                            ) : profilePicture ? (
                                <div className="profile-picture">
                                    <img src={profilePicture} alt={username} loading="lazy"/>
                                </div>
                            ) : null}
                        </div>
                        <button onClick={handleSave} disabled={loading}>
                            {loading ? <Spinner size={20} color='#fff' strokeWidth={7} /> : 'Save'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserProfile;
