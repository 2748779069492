// src/redux/slices/checklistSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';

// Async actions

export const fetchChecklists = createAsyncThunk(
    'checklists/fetchChecklists',
    async (cardId, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/checklists`, {
                headers: {
                    'Accept': 'application/json',
          'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            return data.checklists;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const addChecklistItem = createAsyncThunk(
    'checklists/addChecklistItem',
    async ({ cardId, checklistId, newItem }, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/checklists/${checklistId}/items`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
          'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ title: newItem, checked: false })
            });
            const data = await response.json();
            return data.checklists;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateChecklistItem = createAsyncThunk(
    'checklists/updateChecklistItem',
    async ({ cardId, checklistId, itemId, title, checked }, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/checklists/${checklistId}/items/${itemId}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
          'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ title, checked })
            });
            const data = await response.json();
            return data.checklists;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const deleteChecklistItem = createAsyncThunk(
    'checklists/deleteChecklistItem',
    async ({ cardId, checklistId, itemId }, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('accessToken');
            await fetch(config.API_URI + `/api/cards/${cardId}/checklists/${checklistId}/items/${itemId}`, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
          'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            return { checklistId, itemId };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const deleteChecklist = createAsyncThunk(
    'checklists/deleteChecklist',
    async ({ cardId, checklistId }, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('accessToken');
            await fetch(config.API_URI + `/api/cards/${cardId}/checklists/${checklistId}`, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
          'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            return { checklistId };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const checklistSlice = createSlice({
    name: 'checklists',
    initialState: {
        checklists: [],
        newChecklist: null,
        status: 'idle',
        error: null
    },
    reducers: {
        localDeleteChecklist: (state, action) => {
            const { checklistId } = action.payload;
            state.checklists = state.checklists.filter(checklist => checklist._id !== checklistId);
        },
        // Optimistic local update for add checklist item
        localAddItem: (state, action) => {
            const { checklistId, newItem } = action.payload;
            const checklist = state.checklists.find(checklist => checklist._id === checklistId);
            if (checklist) {
                checklist.items.push({
                    _id: new Date().toISOString(), // Temporary ID for optimistic UI
                    title: newItem,
                    checked: false
                });
            }
        },
        // Optimistic local update for update checklist item
        localUpdateItem: (state, action) => {
            const { checklistId, itemId, title, checked } = action.payload;
            const checklist = state.checklists.find(checklist => checklist._id === checklistId);
            if (checklist) {
                const item = checklist.items.find(item => item._id === itemId);
                if (item) {
                    item.title = title;
                    item.checked = checked;
                }
            }
        },
        // Optimistic local update for delete checklist item
        localDeleteItem: (state, action) => {
            const { checklistId, itemId } = action.payload;
            const checklist = state.checklists.find(checklist => checklist._id === checklistId);
            if (checklist) {
                checklist.items = checklist.items.filter(item => item._id !== itemId);
            }
        },
        // Optimistic checklist addition
        localAddChecklist: (state, action) => {
            state.checklists.push(action.payload);
            state.newChecklist = action.payload;
        },
        localNewChecklist: (state, action) => {
            state.newChecklist = action.payload;
        },
        // Optionally handle the error case where the API fails, you can revert the optimistic change here
        revertChecklistAddition: (state, action) => {
            const { tempId } = action.payload;
            state.checklists = state.checklists.filter(checklist => checklist.tempId !== tempId);
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchChecklists.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchChecklists.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.checklists = action.payload;
            })
            .addCase(fetchChecklists.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(addChecklistItem.fulfilled, (state, action) => {
                // Reconcile optimistic UI with server data
                state.checklists = action.payload;
            })
            .addCase(updateChecklistItem.fulfilled, (state, action) => {
                // Reconcile optimistic UI with server data
                state.checklists = action.payload;
            })
            .addCase(deleteChecklistItem.fulfilled, (state, action) => {
                const { checklistId, itemId } = action.payload;
                const checklist = state.checklists.find(checklist => checklist._id === checklistId);
                if (checklist) {
                    checklist.items = checklist.items.filter(item => item._id !== itemId);
                }
            })
            .addCase(deleteChecklist.fulfilled, (state, action) => {
                const { checklistId } = action.payload;
                state.checklists = state.checklists.filter(checklist => checklist._id !== checklistId);
            });

    }
});

// Export the reducers for optimistic UI
export const { localAddItem, localUpdateItem, localDeleteItem, localDeleteChecklist, localAddChecklist, localNewChecklist, revertChecklistAddition } = checklistSlice.actions;
export default checklistSlice.reducer;
