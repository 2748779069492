import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

import './scss/ProfileNavbar.scss';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import { fetchUserByUsername } from '../../redux/Slices/userSlice';

const ProfileNavbar = () => {
    const dispatch = useDispatch();
    const { userInfo, user, loading, error } = useSelector((state) => state.user);
    const { userName } = useParams();

    useEffect(() => {
        if (userName && (!user || userInfo?.user?.username !== userName)) {
            dispatch(fetchUserByUsername(userName));
        }
    }, [userName, dispatch, userInfo]);

    if (!userName) {
        return null;
    }

    return (
        <div className="top-profile">
            <div className="account-section">
                {userInfo?.user?.profilePicture ? (
                    <img
                        className='profile-picture'
                        src={userInfo?.user?.profilePicture}
                        alt={userName}
                        loading="lazy"
                    />
                ) : (
                    <ImagePlaceholder
                        key={userInfo?.user?._id}
                        size={50}
                        text={userName}
                    />
                )}

                <div className="account-info">
                    <p className="username">{userName}</p>
                    <p className="email">{userInfo?.user?.email}</p>
                </div>
            </div>
            <nav className="navbar">
                <ul>
                    {userInfo?.user?.username === user?.user?.username && (
                        <li><NavLink to={`/u/${userName}/profile`} exact activeClassName="active">Profile and visibility</NavLink></li>
                    )}

                    <li><NavLink to={`/u/${userName}/activity`} exact activeClassName="active">Activity</NavLink></li>
                    <li><NavLink to={`/u/${userName}/cards`} exact activeClassName="active">Cards</NavLink></li>

                    {userInfo?.user?.username === user?.user?.username && (
                        <li><NavLink to={`/u/${userName}/settings`} exact activeClassName="active">Settings</NavLink></li>
                    )}
                </ul>
            </nav>
        </div>
    );
};

export default ProfileNavbar;
