import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";
import Checkbox from "./Checkbox";
import { config } from "../../config";
import "./scss/Notifications.scss";
import { Link } from "react-router-dom";
import ImagePlaceholder from "./ImagePlaceholder";
import Spinner from "./Spinner";

const socket = io(config.API_URI);

const Notifications = ({ setIsShowNotifications }) => {
  const [notifications, setNotifications] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchNotifications = async () => {
      setIsLoading(true);
      try {
        const token = localStorage.getItem("accessToken");
        const response = await fetch(`${config.API_URI}/api/notifications?page=1&limit=5`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          setNotifications(data.notifications.slice(0, 5)); // Ensure only 5
        } else {
          console.error("Failed to fetch notifications");
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNotifications();

    if (user) {
      const userId = user?.user?._id;
      socket.emit("join", userId);

      socket.on("newNotification", (notification) => {
        setNotifications((prev) => [notification, ...prev.slice(0, 4)]); // Keep only 5
      });

      return () => {
        socket.off("newNotification");
      };
    }
  }, [user]);

  const handleMarkAllAsRead = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      await fetch(`${config.API_URI}/api/notifications/mark-all-read`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setNotifications((prev) => prev.map((n) => ({ ...n, read: true })));
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  const markAsRead = async (notificationId) => {
    try {
      const token = localStorage.getItem("accessToken");
      await fetch(`${config.API_URI}/api/notifications/${notificationId}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      setNotifications((prev) =>
        prev.map((n) => (n._id === notificationId ? { ...n, read: true } : n))
      );
    } catch (error) {
      console.error(`Error marking notification ${notificationId} as read:`, error);
    }
  };

  const hideNotificationPanel = () => {
    setIsShowNotifications(false);
  };

  const filteredNotifications = isChecked
    ? notifications.filter((n) => !n.read)
    : notifications;

  return (
    <div className="notifications-panel">
      <div className="header">
        <div className="actions">
          <h2>Notifications</h2>
          {filteredNotifications.length > 0 && (
            <span className="show-unread-checkbox">
              Only show unread
              <Checkbox checked={isChecked} onChange={setIsChecked} />
            </span>
          )}
        </div>
        {filteredNotifications.length > 0 && (
          <button onClick={handleMarkAllAsRead}>Mark all as read</button>
        )}
      </div>

      {isLoading ? (
        <div className="spin-container">
          <Spinner />
        </div>
      ) : filteredNotifications.length === 0 ? (
        <div className="notification-empty-content">
          <span className="material-symbols-outlined">notifications</span>
          <h6>Your notifications live here</h6>
          <p>Stay updated with your latest notifications here.</p>
        </div>
      ) : (
        <ul className="notification-list">
          {filteredNotifications.map((notification) => {
            if (!notification.initiator) return null;

            return (
              <li key={notification._id} className={`notification-item ${!notification.read ? "unread" : ""}`}>
                <div className="user-avatar">
                  {notification.initiator.profilePicture ? (
                    <img
                      src={notification.initiator.profilePicture}
                      alt={notification.initiator.name}
                      style={{ width: "35px", height: "35px", borderRadius: "50%" }}
                      loading="lazy"
                    />
                  ) : (
                    <ImagePlaceholder size={35} text={notification.initiator.username} />
                  )}
                </div>
                <div className="notification-content">
                  <a className="initiator" href="#">
                    {notification.initiator.username}
                  </a>
                  <br />
                  <span className="content" dangerouslySetInnerHTML={{ __html: notification.message }}></span>
                  {notification.targetType && (
                    <a className="target-type" href={`/c/${notification.targetId}`}>
                      {notification.targetType}
                    </a>
                  )}
                  <span className="notification-date">
                    {new Date(notification.createdAt).toLocaleString()}
                  </span>
                </div>
                {!notification.read && <span className="unread-indicator"></span>}
                {notification.targetType && (
                  <Link
                    className="target-link"
                    onClick={() => {
                      markAsRead(notification._id);
                      setIsShowNotifications(false);
                    }}
                    to={`/c/${notification.targetId}`}
                  ></Link>
                )}
              </li>
            );
          })}
          <li className="prev-notification-link">
            <Link to="/notifications" onClick={hideNotificationPanel}>
              See All Notifications
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Notifications;
