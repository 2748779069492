import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../redux/Slices/toastSlice';
import ZoobbeSelect from '../Global/ZoobbeSelect';
import './scss/CreateWorkspace.scss';
import { useNavigate } from 'react-router-dom';
import { config } from '../../config';
import { closeModal } from '../../redux/Slices/modalSlice';
import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';

const CreateBoard = ({ defaultSelectedOption }) => {
  const { workspaces } = useSelector((state) => state.workspaces);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [boardTitle, setBoardTitle] = useState('');
  const [selectedBackground, setSelectedBackground] = useState(null);
  const [unsplashImages, setUnsplashImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Creating...');

  const titleInputRef = useRef(null); // Create a reference

  useEffect(() => {
    if (titleInputRef.current) {
      titleInputRef.current.focus(); // Focus on input when component mounts
    }
  }, []);

  const workspaceOptions = workspaces?.map((workspace) => ({
    value: workspace._id,
    label: workspace.name,
  }));

  const visibilityOptions = [
    { value: 'Workspace', label: 'Workspace' },
    { value: 'Public', label: 'Public' },
    { value: 'Private', label: 'Private' },
  ];

  const [selectedWorkspace, setSelectedWorkspace] = useState(
    workspaceOptions[defaultSelectedOption]
  );
  const [selectedVisibility, setSelectedVisibility] = useState(
    visibilityOptions[0]
  );

  useEffect(() => {
    fetchUnsplashImages();
  }, []);

  useEffect(() => {
    if (unsplashImages.length > 0) {
      setSelectedBackground(unsplashImages[0].urls.regular);
      uploadBackground(unsplashImages[0].urls.regular);
    }
  }, [unsplashImages]);

  useEffect(() => {
    let timer;
    if (loading) {
      timer = setTimeout(() => {
        setLoadingText('Configuring...');
      }, 3000);
    } else {
      setLoadingText('Creating...');
    }
    return () => clearTimeout(timer);
  }, [loading]);

  const handleCreateBoard = async (e) => {
    e.preventDefault();
    if (!boardTitle) return;

    setLoading(true);
    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(config.API_URI + `/api/boards`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          workspaceId: selectedWorkspace?.value,
          title: boardTitle,
          background: selectedBackground,
          coverUrl: selectedBackground,
          visibility: selectedVisibility?.value
        }),
      });

      if (!response.ok) {
        dispatch(showToast({ message: 'Failed to create board', type: 'error' }));
        throw new Error('Failed to create board.');
      }

      const data = await response.json();
      dispatch(showToast({ message: data.message, type: 'success' }));
      dispatch(closeModal());
      dispatch(fetchWorkspaces());
      navigate(data.board.permalink);
    } catch (error) {
      dispatch(
        showToast({ message: 'Error creating board: ' + error, type: 'error' })
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchUnsplashImages = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(`${config.API_URI}/api/unsplash?page=1&limit=4`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch images: ${response.statusText}`);
      }

      const data = await response.json();
      if (!data.success || !Array.isArray(data.photos)) {
        throw new Error('Unexpected response format from the server');
      }

      // Limit to 4 images
      setUnsplashImages(data.photos.slice(0, 4));
    } catch (error) {
      console.error('Error fetching Unsplash images:', error);
      dispatch(showToast({ message: 'Failed to fetch Unsplash images', type: 'error' }));
    }
  };

  const colors = Array.from({ length: 6 }, (_, index) => ({
    id: index + 1,
    urls: {
      thumb: `https://cloud.zoobbe.com/backgrounds/colors/color-${index + 1}.svg`,
      regular: `https://cloud.zoobbe.com/backgrounds/colors/color-${index + 1}.svg`
    }
  }));

  const uploadBackground = async (url) => {

    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(`${config.API_URI}/api/boards/boards-background`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ url }),
      });

      if (!response.ok) {
        throw new Error(`Failed to set background: ${response.statusText}`);
      }

      const data = await response.json();
      setSelectedBackground(data.cover.url);
      dispatch(showToast({ message: 'Background updated successfully', type: 'success' }));
    } catch (error) {
      console.error('Error setting background:', error);
      // dispatch(showToast({ message: 'Failed to set background', type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleBackgroundClick = (imageUrl) => {
    setSelectedBackground(imageUrl);
    uploadBackground(imageUrl);
  };
  const handleColorClick = (imageUrl) => {
    setSelectedBackground(imageUrl);
  };


  return (
    <div className="create-board-form">
      <form onSubmit={handleCreateBoard}>
        <h1>Create Board</h1>

        {/* Background Selection */}
        <div className="group">
          <div
            className="board-preview"
            style={{ backgroundImage: `url(${selectedBackground})` }}
          >
            <img src="../../preview.svg" alt="Preview" loading="lazy" />
          </div>
        </div>

        <div className="group">
          <h3>Background</h3>
          <div className="background-selector">
            {unsplashImages.map((image) => (
              <div
                key={image.id}
                className={`background-option ${selectedBackground === image.urls.regular ? 'selected' : ''
                  }`}
                onClick={() => handleBackgroundClick(image.urls.regular)}
                style={{ backgroundImage: `url(${image.urls.thumb})` }}
              >
                {selectedBackground === image.urls.regular && (
                  <span className="material-icons checkmark">
                    check
                  </span>
                )}
              </div>
            ))}
          </div>
          <div className="background-selector colors">
            {colors.map((color) => (
              <div
                key={color.id}
                className={`background-option ${selectedBackground === color.urls.regular ? 'selected' : ''}`}
                onClick={() => handleColorClick(color.urls.regular)}
                style={{ backgroundImage: `url(${color.urls.thumb})` }}
              >
                {selectedBackground === color.urls.regular && (
                  <span className="material-icons checkmark">
                    check
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Board Title */}
        <div className="group">
          <h3 htmlFor="board-title">Board Title*</h3>
          <input
            type="text"
            id="board-title"
            value={boardTitle}
            onChange={(e) => setBoardTitle(e.target.value)}
            placeholder="Enter Board Title"
            className={!boardTitle ? 'error' : ''}
            ref={titleInputRef}
          />
          {!boardTitle && (
            <p className="error-message">👋 Board title is required</p>
          )}
        </div>

        <div className="group">
          <h3>Workspace</h3>
          <ZoobbeSelect
            options={workspaceOptions}
            defaultSelectedOption={defaultSelectedOption}
            onSelect={(workspace) => setSelectedWorkspace(workspace)}
          />
        </div>

        <div className="group">
          <h3>Visibility</h3>
          <ZoobbeSelect
            options={visibilityOptions}
            defaultSelectedOption={0}
            onSelect={(visibility) => setSelectedVisibility(visibility)}
          />
        </div>

        <button type="submit" disabled={!boardTitle || loading} className={(!boardTitle || loading) && 'disabled'}>
          {loading ? loadingText : 'Create'}
        </button>
      </form>
    </div>
  );
};

export default CreateBoard;
