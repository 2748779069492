import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Spinner from '../Global/Spinner';

// Local Utilities and Components
import {
  find,
  getChecklistStatus,
  getTextColor,
  isSafari,
  relative,
  rgbaColor,
  toSlug,
} from '../../utils/helpers';

import MemberImage from '../Global/MemberImage';
import useHandlePopoverClick from '../../hooks/useHandlePopoverClick';
import { config } from '../../config';
import { handleArchiveCard, removeArchivedCard } from '../../redux/Slices/cardSlice';
// import CardPreview from './CardPreview';

const ArchivedCard = ({ card, setBoard }) => {
  // Destructure Card Properties
  const {
    _id,
    title,
    permalink,
    users,
    labels,
    watchers,
    checklists,
    description,
    comments,
    attachments,
    actionList,
    shortId,
    cover,
    order
  } = card || {};


  const members = users;

  // Refs
  const cardRef = useRef(null);

  // Redux and Router
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  const { handlePopoverClick } = useHandlePopoverClick();

  const [isLoading, setLoading] = useState(false);
  // const { status } = useSelector((state) => state.card);

  // console.log({ status });

  // Early Return if Card is Null or Undefined
  if (!card) return null;

  // Derived Variables
  const checklistsStatus = getChecklistStatus(checklists);


  // Determine if Footer Should Be Rendered
  const shouldRenderFooter =
    (watchers?.length > 0 && watchers.some(watcher => watcher._id === user?.user?._id)) ||
    !!description ||
    (comments?.length > 0) ||
    (checklistsStatus[1] > 0) ||
    (attachments?.length > 0) ||
    (members.length > 0);

  const handleOpenCard = (e, cardId) => {
    navigate(relative(permalink));
  };

  const handleSendToBoard = async (archivedStatus, cardId) => {
    // setArchived(archivedStatus); // Update local state to reflect change instantly
    if (isLoading) return;
    setLoading(true);

    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch(config.API_URI + `/api/cards/archive/${cardId}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ archived: archivedStatus }),
      });

      if (!response.ok) {
        throw new Error('Failed to archive the card');
      }

      const data = await response.json();
      setLoading(false);

      // If successfully unarchived, remove it from the Redux store
      dispatch(removeArchivedCard(cardId));

      setBoard((prevBoard) => {
        const { updatedCard, actionList } = data;
        let listExists = prevBoard.actionLists.some((list) => list._id === actionList._id);

        return {
          ...prevBoard,
          actionLists: listExists
            ? prevBoard.actionLists.map((list) => {
              if (list._id === updatedCard.actionList._id) {
                const newCards = archivedStatus
                  ? list.cards.filter((card) => card.shortId !== cardId)
                  : [...list.cards];

                if (!archivedStatus) {
                  const insertIndex = newCards.findIndex((card) => card.order > updatedCard.order);
                  if (insertIndex === -1) {
                    newCards.push(updatedCard);
                  } else {
                    newCards.splice(insertIndex, 0, updatedCard);
                  }
                }

                return {
                  ...list,
                  cards: newCards.sort((a, b) => a.order - b.order),
                };
              }
              return list;
            })
            : [
              ...prevBoard.actionLists,
              {
                ...actionList,
                cards: [updatedCard],
              },
            ],
        };
      });


      console.log('Card archive status updated:', data);
    } catch (error) {
      // Optionally revert the UI if the API request fails
      // setArchived(!archivedStatus);
      console.error('Error archiving the card:', error);
      setLoading(false);

    }
  };

  // const handleUnarchive = () => {
  //   dispatch(handleArchiveCard({ archivedStatus: false, cardId: shortId }));
  // };

  const coverColor = cover?.coverColor || [];

  return (
    <div
      className='card-wrapper'
      ref={cardRef}
    >

      <div
        className={`card-pemalink-wrapper`} >
        <>
          <div className="zoobbe-card">
            {
              cover && cover.url && (
                <div className="zoobbe-card-cover-image">
                  <img className='cover-image' src={cover.url} alt={cover.name} loading="lazy" />
                </div>
              )
            }
            {labels && labels.length > 0 && (
              <div className="card-header">
                <div className="card-labels">
                  {labels.map(label => (
                    <span
                      key={label._id}
                      style={{ backgroundColor: label.color, color: getTextColor(label.color) }}
                      className={`label ${toSlug(label.text)}-label`}
                      onClick={() => console.log(label.text)}
                      data-labelid={label._id}
                    >
                      {label.text}
                    </span>
                  ))}
                </div>
              </div>
            )}


            <p>{title}</p>

            {shouldRenderFooter && (
              <div className="zoobbe-card-footer">
                {members.length > 0 && (
                  <div className="avatars">
                    <MemberImage cardId={_id} members={members} type={'card'} size={28} />
                  </div>
                )}

                <div className="zoobbe-card-front-badges">
                  {watchers?.length > 0 && watchers.some(watcher => watcher._id === user?.user?._id) && (
                    <span className="badge-card-subscribe badge-icon">
                      <span className="material-icons-outlined">notifications</span>
                    </span>
                  )}
                  {description && (
                    <span className="badge-card-description badge-icon">
                      <span className="material-icons-outlined">subject</span>
                    </span>
                  )}
                  {comments?.length > 0 && (
                    <span className="badge-card-comments badge-icon">
                      <span className="material-icons-outlined">mode_comment</span>
                      <span className="comments-count count-number">{comments.length}</span>
                    </span>
                  )}
                  {checklistsStatus[1] > 0 && (
                    <span className="badge-card-checklists badge-icon">
                      <span className="material-symbols-outlined">task_alt</span>
                      <span className="checklists-count count-number">{checklistsStatus[0]}/{checklistsStatus[1]}</span>
                    </span>
                  )}
                  {attachments?.length > 0 && (
                    <span className="badge-card-attachments badge-icon">
                      <span className="material-symbols-outlined">attach_file</span>
                      <span className="attachment-count count-number">{attachments.length}</span>
                    </span>
                  )}

                  <div className="archive-actions">
                    <div className="send-to-board" onClick={() => handleSendToBoard(false, shortId)} data-tooltip-content="Send to board" data-tooltip-position="top">
                      {
                        isLoading ? (
                          <Spinner size={15} color="#ffffff" speed={1.5} strokeWidth={5} className={'send-to-board-spinner'} />
                        ) : (
                          <span className="material-symbols-outlined">refresh</span>
                        )
                      }

                    </div>

                    <div
                      className="delete-archive-card"
                      id={'popover-delete-archived-card-' + shortId}
                      onClick={(e) => handlePopoverClick(e, 'deleteCard', { cardId: shortId })}
                      data-tooltip-content="Delete"
                      data-tooltip-position="top"
                      data-popover-trigger
                    >
                      <span className="material-symbols-outlined">delete</span>
                    </div>
                  </div>

                </div>

              </div>
            )}
          </div>
          <div
            title={title}
            onClick={(e) => handleOpenCard(e, _id)}
            className="card-pemalink"
          ></div>
        </>

        {/* <div className="send-to-board" onClick={() => handleSendToBoard(false, shortId)} data-tooltip-content="Send to board" data-tooltip-position="top">
          {
            isLoading ? (
              <Spinner size={15} color="#ffffff" speed={1.5} strokeWidth={5} className={'send-to-board-spinner'} />
            ) : (
              <span className="material-symbols-outlined">refresh</span>
            )
          }

        </div>

        <div
          className="delete-archive-card"
          id={'popover-delete-archived-card-' + shortId}
          onClick={(e) => handlePopoverClick(e, 'deleteCard', { cardId: shortId })}
          data-tooltip-content="Delete"
          data-tooltip-position="top"
          data-popover-trigger
        >
          <span className="material-symbols-outlined">delete</span>
        </div> */}

      </div>
    </div>
  );

};

export default ArchivedCard;
