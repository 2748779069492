import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useHandlePopoverClick from '../../hooks/useHandlePopoverClick';
import StarFill from '../icons/StarFill';
import { config } from '../../config';
import { useParams } from 'react-router-dom';
import { fetchStarredBoards } from '../../redux/Slices/boardsSlice';
import { fetchUser } from '../../redux/Slices/thunks';

const NavbarLeft = ({ boardId, board, setBoard }) => {
    const dispatch = useDispatch();

    const { handlePopoverClick } = useHandlePopoverClick();
    const { user } = useSelector((state) => state.user);

    const [isStarred, setIsStarred] = useState(user.user.starredBoards?.includes(board._id) || false);
    const [editedTitle, setEditedTitle] = useState(board?.title || '');
    const [inputWidth, setInputWidth] = useState(0);
    const [isEditingTitle, setIsEditingTitle] = useState(false);

    const hiddenSpanRef = useRef(null);

    useEffect(() => {
        if (hiddenSpanRef.current) {
            // Adjust the input width based on the span's content width
            setInputWidth(hiddenSpanRef.current.offsetWidth + (editedTitle.length * 1)); // Add some padding
        }
    }, [editedTitle]);

    // Toggle star status
    const toggleStar = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            const action = isStarred ? 'remove' : 'add';

            const response = await fetch(`${config.API_URI}/api/users/me/stared`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ boardId, action }),
            });

            if (!response.ok) throw new Error('Failed to update star status');

            setIsStarred(!isStarred); // Toggle star state
            dispatch(fetchUser());
            dispatch(fetchStarredBoards());
        } catch (error) {
            console.error('Error toggling star status:', error);
        }
    };

    // Handle saving the edited title
    const handleSaveTitle = async () => {
        try {
            if (editedTitle !== board?.title) {
                const token = localStorage.getItem('accessToken');

                const response = await fetch(`${config.API_URI}/api/boards/${boardId}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ title: editedTitle }),
                });

                if (!response.ok) throw new Error('Failed to update board title');

                const updatedBoard = await response.json();
                // setBoard(updatedBoard); // Update parent state
            }
        } catch (error) {
            console.error('Error updating board title:', error);
        } finally {
            setIsEditingTitle(false);
        }
    };

    const handleTitleChange = (e) => {
        setEditedTitle(e.target.value);
    };

    const handleFocus = (e) => {
        e.target.select();
    };

    return (
        <ul className="zoobbe-navbar-left">
            <li className="zoobbe-workspace-name">
                {/* Hidden span to measure the text width */}
                <span
                    ref={hiddenSpanRef}
                    className="zoobbe-board-title-hidden"
                    style={{
                        position: 'absolute',
                        visibility: 'hidden',
                        whiteSpace: 'pre',
                    }}
                >
                    {editedTitle || 'Untitled'}
                </span>

                <input
                    className="zoobbe-board-title"
                    value={editedTitle}
                    onChange={handleTitleChange}
                    onBlur={handleSaveTitle}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') handleSaveTitle();
                        if (e.key === 'Escape') setIsEditingTitle(false);
                    }}
                    onFocus={handleFocus}
                    style={{
                        width: `${inputWidth}px`,
                        cursor: 'pointer'
                    }}
                    data-tooltip-content={'Board Title'}
                    data-tooltip-position="top"
                />
            </li>
            <li
                className="zoobbe-star"
                onClick={toggleStar}
                data-tooltip-content={isStarred ? 'Starred' : 'Star'}
                data-tooltip-position="top"
            >
                {!isStarred ? (
                    <span className="material-symbols-outlined">kid_star</span>
                ) : (
                    <StarFill color={isStarred ? '#FFD700' : '#fff'} />
                )}
            </li>
            <li
                className="zoobbe-board-visibility"
                onClick={(e) =>
                    handlePopoverClick(e, 'boardVisibility', {
                        boardId,
                        visibility: board.visibility,
                        setBoard,
                    })
                }
                data-popover-trigger
            >
                {board?.visibility === 'Workspace' && (
                    <span className="material-symbols-outlined" data-tooltip-content="Workspace visibility" data-tooltip-position="top">group</span>
                )}
                {board?.visibility === 'Public' && (
                    <span className="material-symbols-outlined" data-tooltip-content="Public" data-tooltip-position="top">public</span>
                )}
                {board?.visibility === 'Private' && (
                    <span className="material-symbols-outlined" data-tooltip-content="Private" data-tooltip-position="top">lock</span>
                )}
                {!board?.visibility && (
                    <span className="material-symbols-outlined" data-tooltip-content="Private" data-tooltip-position="top">lock</span>
                )}

                {/* {board?.visibility
                    ? board.visibility === 'Workspace'
                        ? `${board.visibility} visibility`
                        : board.visibility
                    : 'Private'} */}
            </li>

            {/* <li
                className={`board-archived-items`}
                onClick={(e) => handlePopoverClick(e, 'navAcrhived', { boardId: board.shortId, className: 'nav-archived-popover', isInNav: true })}
                data-popover-trigger>
                <span
                    className="material-symbols-outlined"
                    data-tooltip-content="Archive Items"
                    data-tooltip-position="top"
                >
                    archive
                </span>
            </li> */}
        </ul>
    );
};

export default NavbarLeft;
