import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { showToast } from '../../redux/Slices/toastSlice';
import { selectBoardId } from '../../redux/Slices/boardIdSlice';
import { fetchBoardMembers } from '../../redux/Slices/boardMembersSlice';
import ZoobbeSelect from '../Global/ZoobbeSelect';
import Spinner from '../Global/Spinner';


import { togglePopover } from '../../redux/Slices/popoverSlice';
import { fetchMembers, memberInfo } from '../../redux/Slices/memberSlice';
import { config } from '../../config';

import { fetchUsers } from '../../redux/Slices/thunks';

import ImagePlaceholder from '../Global/ImagePlaceholder';
import { find, getOptionIndex, isValidEmail } from '../../utils/helpers';

import './scss/ShareBoard.scss';
import '../Global/scss/MembersSearchResult.scss';
import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';
import useOutsideClick from '../../hooks/useOutsideClick';
import useHandlePopoverClick from '../../hooks/useHandlePopoverClick';

const ShareBoardModal = ({ workspaceSlug }) => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [message, setMessage] = useState("We're excited to have you on board. Let’s get started!");
    const [activeTab, setActiveTab] = useState('members');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedRole, setSelectedRole] = useState({ value: 'member', label: 'Member' });
    const [popoverState, setPopoverState] = useState({ isVisible: false, contentId: null });
    const [size] = useState(35);
    const [isFocused, setIsFocused] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState({});
    const [openMemberId, setOpenMemberId] = useState(null); // Track which member is open


    const { board } = useSelector((state) => state.board);
    const [inviteLink, setInviteLink] = useState(board?.joinLink);

    const emailRef = useRef();
    const memberRef = useRef();

    const { users } = useSelector((state) => state.users);
    const { user } = useSelector((state) => state.user);

    const boardId = useSelector(selectBoardId);

    const collaborators = useSelector(state => state.member.boardMembers);

    const totalAdmins = collaborators.filter(m => m.role === 'admin').length;

    const { workspaceMembers } = useSelector(state => state.member);

    const { handlePopoverClick } = useHandlePopoverClick();

    const [memberRoles, setMemberRoles] = useState(
        collaborators.reduce((acc, member) => ({ ...acc, [member._id]: member.role }), {})
    );


    useEffect(() => {
        if (!users) {
            dispatch(fetchUsers());
        }
    }, [users, dispatch]);

    useEffect(() => {
        if (boardId) {
            dispatch(fetchMembers({ type: 'board', id: boardId }));
        }
    }, [boardId, dispatch]);

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        // Simple email validation regex pattern
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (value?.length > 0) {
            const results = users?.filter((item) =>
                item.name.toLowerCase().includes(value.toLowerCase()) ||
                item.email.toLowerCase().includes(value.toLowerCase())
            );

            // Show email as an invite option if no users are found and input is a valid email format
            if (results.length === 0 && isValidEmail(value)) {
                setSearchResults([{ email: value, isEmailInvite: true }]);
            } else {
                setSearchResults(results);
            }
        } else {
            setSearchResults([]);
        }
    };


    const handleSelectUser = (user) => {
        if (!selectedUsers.some((selected) => selected._id === user._id)) {
            // If the selected option is an existing user
            setSelectedUsers([...selectedUsers, user]);
        }
        else {
            setSelectedUsers([...selectedUsers, { email: user.email, isEmailInvite: true }]);
        }
        setEmail('');
        setSearchResults([]);
    };

    const removeUser = (index) => {
        setSelectedUsers((prev) => prev.filter((_, i) => i !== index));
    };

    const addMemberToBoard = async (e, selectedWorkspaceMembers) => {
        e.preventDefault();

        setLoading(true);

        if (!boardId) {
            dispatch(showToast({ message: 'Board Id Empty', type: 'error' }));
            setLoading(false);
            return;
        }

        try {
            const token = localStorage.getItem('accessToken');

            // Prepare the members array with both memberId and role
            const members = selectedWorkspaceMembers.map((user) => ({
                memberId: user._id || null,
                email: user.email || null,
                role: selectedRole.value || 'member',
            }));



            const response = await fetch(config.API_URI + `/api/boards/${boardId}/member`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    boardId: boardId,
                    members: members,
                    message: message
                }),
            });

            const data = await response.json();

            if (response.status === 200) {
                dispatch(fetchMembers({ type: 'board', id: boardId }));
                const messageType = response.status === 200 ? 'success' : 'error';
                dispatch(showToast({ message: data.message, type: messageType }));
                setLoading(false);
                setSelectedUsers([]);
            }
            else {
                dispatch(showToast({ message: data.message, type: 'error' }));
                setLoading(false);
            }
        } catch (error) {
            console.error('Error adding member:', error);
            dispatch(showToast({ message: 'Error adding member', type: 'error' }));
        }
    };

    const handleInviteByEmail = async (e, selectedNonWorkspaceMembers) => {
        e.preventDefault();

        if (selectedNonWorkspaceMembers.length === 0) {
            setLoading(false);
            dispatch(showToast({ message: 'Please select at least one user', type: 'error' }));
            return;
        }

        const memberId = selectedNonWorkspaceMembers[0]._id; // Assuming you're sending one invitee for re-invite

        setLoadingUsers(prev => ({ ...prev, [memberId]: true }));

        try {
            const token = localStorage.getItem('accessToken');
            const invitees = selectedNonWorkspaceMembers.map((user) => ({
                email: user.email,
                role: selectedRole.value || 'member',
            }));

            const response = await fetch(`${config.API_URI}/api/boards/${boardId}/invite`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ invitees, message }),
            });

            const data = await response.json();

            if (response.ok) {
                dispatch(fetchMembers({ type: 'board', id: boardId }));
                dispatch(showToast({ message: 'Invitations sent!', type: 'success' }));
                setSelectedUsers([]);
                setLoading(false);
            } else {
                dispatch(showToast({ message: data.message, type: 'error' }));
            }
        } catch (error) {
            dispatch(showToast({ message: 'Failed to send invite', type: 'error' }));
        } finally {
            setLoadingUsers(prev => ({ ...prev, [memberId]: false }));
        }
    };

    const handleGenerateJoinLink = async () => {
        try {
            const token = localStorage.getItem('accessToken');

            const response = await fetch(`${config.API_URI}/api/boards/${boardId}/generate-join-link`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            const data = await response.json();
            if (response.ok) setInviteLink(data?.joinLink);
            else dispatch(showToast({ message: data.message, type: 'error' }));
        } catch (error) {
            dispatch(showToast({ message: 'Failed to generate join link', type: 'error' }));
        }
    };

    const handleDeleteJoinLink = async () => {
        try {
            const token = localStorage.getItem('accessToken');

            const response = await fetch(`${config.API_URI}/api/boards/${boardId}/delete-join-link`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                setInviteLink(null);
                dispatch(showToast({ message: 'Join link deleted', type: 'success' }));
            } else {
                const data = await response.json();
                dispatch(showToast({ message: data.message, type: 'error' }));
            }
        } catch (error) {
            dispatch(showToast({ message: 'Failed to delete join link', type: 'error' }));
        }
    };


    const handleMembersShare = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (selectedUsers.length === 0) {
            setLoading(false);
            dispatch(showToast({ message: 'Please select at least one user', type: 'error' }));
            return;
        }

        const selectedWorkspaceMembers = selectedUsers.filter(user =>
            workspaceMembers.some(member => member._id === user._id)
        );

        const selectedNonWorkspaceMembers = selectedUsers.filter(user =>
            !workspaceMembers.some(member => member._id === user._id)
        );


        if (selectedWorkspaceMembers.length > 0) {
            // Add members who are part of the workspace to the board
            await addMemberToBoard(e, selectedWorkspaceMembers);
        }

        {/* Will support it letter */ }

        // if (selectedNonWorkspaceMembers.length > 0) {
        //     // Send invitation emails to users who are not part of the workspace
        //     await handleInviteByEmail(e, selectedNonWorkspaceMembers);
        // }

        if (selectedNonWorkspaceMembers.length > 0) {
            // Send invitation emails to users who are not part of the workspace
            await addMemberToBoard(e, selectedNonWorkspaceMembers);
        }
    };

    const handleRoleSelect = (role) => {
        if (role.value === 'remove') return;
        setSelectedRole(role);
    };

    const handleMemberInfo = (e, contentId, member) => {
        const target = e.currentTarget;
        const rect = target.getBoundingClientRect();
        const position = { top: rect.bottom, left: rect.left };

        if (popoverState.isVisible && popoverState.contentId === contentId) {
            setPopoverState({ isVisible: false, contentId: null });
            dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));
        } else {
            setPopoverState({ isVisible: true, contentId });
            dispatch(togglePopover({ contentId, position, targetId: target.id }));
            dispatch(memberInfo({ member }));
        }
    };

    const roleOptions = [
        { value: 'admin', label: 'Admin' },
        { value: 'member', label: 'Member' },
        { value: 'observer', label: 'Observer' },
    ];

    const handleFocus = () => {
        setIsFocused(true); // Set focus to true
    };

    useOutsideClick(emailRef, () => {
        setIsFocused(false);
        setOpenMemberId(null);

    });

    const handeReInvite = async (e, member) => {
        e.preventDefault();

        setLoadingUsers(prev => ({ ...prev, [member._id]: true }));

        try {
            await handleInviteByEmail(e, [member]);
        } finally {
            setLoadingUsers(prev => ({ ...prev, [member._id]: false }));
        }
    };

    const handleCopyLink = () => {
        if (inviteLink) {
            navigator.clipboard.writeText(inviteLink);
            dispatch(showToast({ message: 'Join link copied to clipboard!', type: 'success' }));
        }
    };


    return (
        <div className="sbm-share-board-modal">
            <div className="sbm-header">
                <h3>Share Board</h3>
            </div>
            <form onSubmit={handleMembersShare}>
                <div className="sbm-input-group">
                    <div
                        className="sbm-email-input"
                        ref={emailRef}
                        style={{
                            outline: isFocused ? '2px solid var(--brand-color)' : '1px solid var(--input-border-color)',
                        }}
                    >
                        {selectedUsers.map((selectedUser, index) => (
                            <div key={index} className="sbm-selected-email">
                                {`${selectedUser.name || selectedUser.email}`}
                                <button type="button" onClick={() => removeUser(index)}>
                                    <span className="material-icons">
                                        close
                                    </span>
                                </button>
                            </div>
                        ))}
                        <input
                            type="text"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="Type name or email"
                            autoComplete="off"
                            autoCapitalize="none"
                            spellCheck="false"
                            onFocus={handleFocus}
                        />
                        {
                            searchResults.length > 0 ? (
                                <div className="sbm-search-results">
                                    {searchResults.map((suggestion, index) => (
                                        <div
                                            key={index}
                                            className="mention-suggestion"
                                            onClick={() => handleSelectUser(suggestion)}
                                        >
                                            {suggestion.isEmailInvite ? (
                                                <>
                                                    <ImagePlaceholder key={suggestion._id} size={size} text={suggestion.email} />
                                                    <div>
                                                        <div className="suggession-name">{suggestion.email}</div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    {suggestion.profilePicture ? (
                                                        <img src={suggestion.profilePicture} alt={suggestion.name} loading="lazy" />
                                                    ) : (
                                                        <ImagePlaceholder key={suggestion._id} size={size} text={suggestion.username} />
                                                    )}
                                                    <div>
                                                        <div className="suggession-name">{suggestion.name}</div>
                                                        <div className="suggession-handler">{`@${suggestion.username}`}</div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                // Show the "no user found" message if no results and no valid email
                                email && !isValidEmail(email) && (
                                    <div className="sbm-search-results">
                                        <div className='no-user-message'>
                                            Looks like that person isn't a Zoobbe member yet. Add their email address to invite them.
                                        </div>
                                    </div>
                                )
                            )
                        }



                    </div>
                    <ZoobbeSelect
                        options={roleOptions}
                        defaultSelectedOption={1}
                        onSelect={handleRoleSelect}
                    />
                </div>
                <button type="submit" className={`sbm-invite-button${loading ? ' disabled' : ''}`} disabled={loading}>
                    {loading ? <Spinner size={20} color='#fff' strokeWidth={7} /> : 'Share'}
                </button>
            </form>
            <textarea
                className="sbm-message-input"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />
            <div className="sbm-invite-link">
                {inviteLink ? (
                    <div className="invite-link-info">
                        <span className="material-symbols-outlined">
                            link
                        </span>
                        <span className="invite-link-text">Anyone with the link can join as a member</span>
                        <span className="invite-link-actions">
                            <button onClick={handleCopyLink} className="link-action">Copy link</button> •
                            <button onClick={handleDeleteJoinLink} className="link-action">Delete link</button>
                        </span>
                    </div>
                ) : (
                    <div className="invite-link-info">
                        <span className="material-symbols-outlined">
                            link
                        </span>
                        <span className="invite-link-text">Share this board with a link</span>
                        <span className="invite-link-actions">
                            <button onClick={handleGenerateJoinLink} className="link-action">Create link</button>
                        </span>
                    </div>
                )}
            </div>
            <div className="sbm-tabs">
                <button
                    className={`sbm-tab ${activeTab === 'members' ? 'active' : ''}`}
                    onClick={() => setActiveTab('members')}
                >
                    Board members
                </button>
                <button
                    className={`sbm-tab ${activeTab === 'requests' ? 'active' : ''}`}
                    onClick={() => setActiveTab('requests')}
                >
                    Requests
                </button>
            </div>
            {activeTab === 'members' && (
                <div className="sbm-workspace-members">
                    {collaborators?.map((member, index) => {

                        if (!member?._id) return;

                        const isCurrentUser = member._id === user?.user?._id;

                        const handleRoleChange = (newRole, memberId) => {
                            if (newRole.value === 'admin' && totalAdmins < 2 && member.role !== 'admin') {
                                dispatch(showToast({ message: 'Cannot assign more admins. At least one admin is required.', type: 'error' }));
                                return;
                            }
                            if (member.role === 'admin' && newRole.value !== 'admin' && totalAdmins <= 1) {
                                dispatch(showToast({ message: 'Cannot remove the last admin. At least one admin is required.', type: 'error' }));
                                return;
                            }

                            setMemberRoles((prevRoles) => ({ ...prevRoles, [memberId]: newRole }));

                        };

                        return (
                            <div className="sbm-member" key={index + ' ' + member._id}>
                                <span className="avatar" onClick={(e) => { handleMemberInfo(e, 'memberInfo', member) }} data-popover-trigger>
                                    {member.profilePicture ? (
                                        <img
                                            className="sbm-member-image"
                                            style={{ width: size, height: size }}
                                            src={member.profilePicture}
                                            alt={member.name}
                                            loading="lazy" 
                                        />
                                    ) : (
                                        <ImagePlaceholder size={size} text={member.username} />
                                    )}
                                </span>
                                <div className="shared-members-name">
                                    <span className="sbm-member-name">
                                        {member.name} {isCurrentUser && '(you)'}
                                    </span>

                                    <span className="member-username">@{member.username} • WorkSpace {member.workspaceRole}</span>

                                    {/* Will support it letter */}

                                    {/* {
                                        member.status === 'pending' && (
                                            <span className="member-status">Invitation pending</span>
                                        )
                                    } */}
                                </div>

                                {/* Will support it letter */}
                                {/* {
                                    member.status === 'pending' && (
                                        <div
                                            className={`re-invite${loadingUsers[member._id] ? ' spin-loading' : ''}`}
                                            title='Re-Invite'
                                            onClick={(e) => {
                                                setLoading(false);
                                                handeReInvite(e, member);
                                            }}
                                        >
                                            {loadingUsers[member._id] ? (
                                                <Spinner size={20} color="#333" strokeWidth={7} />
                                            ) : (
                                                <span className="material-symbols-outlined">
                                                    send
                                                </span>
                                            )}
                                        </div>
                                    )
                                } */}

                                <div
                                    className={`members-options ${openMemberId === member._id ? 'active' : ''}`}
                                    id={`popover-member-${member._id}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setOpenMemberId(openMemberId === member._id ? null : member._id);
                                        handlePopoverClick(e, 'memberRole', {
                                            memberId: member._id,
                                            currentRole: memberRoles[member._id],
                                            onSelectRole: handleRoleChange,
                                            isClosed: false,
                                            boardId,
                                            memberDeleteTarget: `popover-member-${member._id}`, // Pass ID instead of the event
                                            totalAdmins,
                                            apiPath: 'board',
                                            typeId: boardId,

                                        });
                                    }}
                                    data-popover-trigger
                                >
                                    {memberRoles[member._id] || member.role}
                                    <span className="material-symbols-outlined">keyboard_arrow_down</span>
                                </div>


                            </div>
                        )
                    })}
                </div>
            )}
            {activeTab === 'requests' && (
                <div className="sbm-requests">No requests found</div>
            )}
        </div>
    );
};

export default ShareBoardModal;
