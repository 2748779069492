import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";
import Checkbox from "../Global/Checkbox";
import { config } from "../../config";
import "./scss/Notifications.scss";
import ImagePlaceholder from "../Global/ImagePlaceholder";
import Spinner from "../Global/Spinner";

const socket = io(config.API_URI);

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [isChecked, setIsChecked] = useState(false);
    const observer = useRef();
    const { user } = useSelector((state) => state.user);

    useEffect(() => {
        fetchNotifications();
    }, [page]);

    useEffect(() => {
        if (user) {
            const userId = user?.user?._id;
            socket.emit("join", userId);

            socket.on("newNotification", (notification) => {
                setNotifications((prev) => [notification, ...prev]);
            });

            return () => {
                socket.off("newNotification");
            };
        }
    }, [user]);

    const fetchNotifications = async () => {
        if (!hasMore || loading) return;

        setLoading(true);
        try {
            const token = localStorage.getItem("accessToken");
            const response = await fetch(`${config.API_URI}/api/notifications?page=${page}&limit=20`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                const data = await response.json();
                setNotifications((prev) => [...prev, ...data.notifications]);
                setHasMore(data.notifications.length > 0);
            } else {
                console.error("Error fetching notifications");
            }
        } catch (error) {
            console.error("Fetch error:", error);
        }
        setLoading(false);
    };

    const lastNotificationRef = (node) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore) {
                setPage((prev) => prev + 1);
            }
        });

        if (node) observer.current.observe(node);
    };

    const handleMarkAllAsRead = async () => {
        try {
            const token = localStorage.getItem("accessToken");
            await fetch(`${config.API_URI}/api/notifications/mark-all-read`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });

            setNotifications((prev) => prev.map((n) => ({ ...n, read: true })));
        } catch (error) {
            console.error("Error marking all as read:", error);
        }
    };

    const filteredNotifications = isChecked
        ? notifications.filter((n) => !n.read)
        : notifications;

    return (
        <div className="notifications-page">
            <div className="notifications-panel">
                <div className="header">
                    <div className="actions">
                        <h2>Notifications</h2>
                        <span className="show-unread-checkbox">
                            Only show unread
                            <Checkbox checked={isChecked} onChange={setIsChecked} />
                        </span>
                    </div>
                    <button onClick={handleMarkAllAsRead}>Mark all as read</button>
                </div>

                {loading && page === 1 ? (
                    <div className="spin-container">
                        <Spinner />
                    </div>
                ) : (
                    <ul className="notification-list">
                        {filteredNotifications?.map((notification, index) => {
                            if (!notification.initiator) return null;

                            const isLastItem = index === filteredNotifications?.length - 1;

                            return (
                                <li
                                    key={notification._id}
                                    className={`notification-item ${!notification.read ? "unread" : ""}`}
                                    ref={isLastItem ? lastNotificationRef : null}
                                >
                                    <div className="user-avatar">
                                        {notification.initiator.profilePicture ? (
                                            <img
                                                src={notification.initiator.profilePicture}
                                                alt={notification.initiator.name}
                                                style={{ width: "35px", height: "35px", borderRadius: "50%" }}
                                                loading="lazy"
                                            />
                                        ) : (
                                            <ImagePlaceholder
                                                key={notification.initiator._id}
                                                size={35}
                                                text={notification.initiator.username}
                                            />
                                        )}
                                    </div>
                                    <div className="notification-content">
                                        <a className="initiator" href="#">
                                            {notification.initiator.username}
                                        </a>
                                        <br />
                                        <span className="content" dangerouslySetInnerHTML={{ __html: notification.message }}></span>
                                        {notification.targetType && (
                                            <a className="target-type" href={`/c/${notification.targetId}`}>
                                                {notification.targetType}
                                            </a>
                                        )}
                                        <span className="notification-date">
                                            {new Date(notification.createdAt).toLocaleString()}
                                        </span>
                                    </div>
                                    {!notification.read && <span className="unread-indicator"></span>}
                                    {notification.targetType && (
                                        <a className="target-link" href={`/c/${notification.targetId}`}></a>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                )}

                {loading && page > 1 && (
                    <div className="spin-container">
                        <Spinner />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Notifications;
